<template>
	<div class="wallet_box">
		<el-breadcrumb class="bread" separator="/">
			<el-breadcrumb-item :to="{ path: '/admin/dashboard' }">
				<div class="box">
					<div class="icon_box">
						<el-icon> <ArrowLeftBold /></el-icon>
					</div>
					<span>{{ $t('card_shop_v_detail.breadcrumb1') }}</span>
				</div>
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/admin/shop/virtual' }">
				{{ $t('card_shop_v_detail.breadcrumb2') }}
			</el-breadcrumb-item>
			<el-breadcrumb-item>{{ $t('card_shop_v_detail.breadcrumb3') }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="wallet">
			<div class="w_body">
				<div class="w_left">
					<div class="card1">
						<img src="../../assets/card/detail_card2.png" alt="" />
						<!-- <button @click="toUrl('/admin/virtualDetail')" class="button" v-show="item.cardFlag">
							立即申请
						</button> -->
						<div class="content_box">
							<div class="number">{{ state.feeInfo.openVirtualCardFee }}USD</div>
							<div class="cardNo">{{ state.itemInfo.bin }}</div>
							<div class="fee">
								{{ $t('card_shop_v_detail.fee1') }}{{ state.feeInfo.virtualCardRechargeFeeRate }}%
							</div>
							<div class="fee">
								{{ $t('card_shop_v_detail.fee2') }}
							</div>
							<div class="cardType">
								{{ state.itemInfo.type }}
							</div>
						</div>
					</div>
				</div>
				<div class="w_right">
					<div class="font">
						<div>
							{{ $t('card_shop_v_detail.number') }}
							<el-input-number
								class="number"
								v-model="value"
								:min="1"
								:max="50"
								size="small"
								controls-position="right"
							/>
							/50卡片
						</div>
						<img class="icon" src="../../assets/shop/icon.png" alt="" />
					</div>
					<el-slider v-model="value" :max="50" />
					<div class="content">
						<div class="item">{{ $t('card_shop_v_detail.item1') }}{{ value }}</div>
						<div class="item">
							{{ $t('card_shop_v_detail.item2') }}{{ state.feeInfo.openVirtualCardFee }}USDT
						</div>
					</div>
					<div class="content">
						<div class="item">
							{{ $t('card_shop_v_detail.item3') }}{{ state.feeInfo.virtualCardRechargeMin }}USDT
						</div>
						<div class="item">
							{{ $t('card_shop_v_detail.item4') }}（{{
								value *
								(state.feeInfo.openVirtualCardFee * 1 +
									state.feeInfo.virtualCardRechargeMin * 1 +
									state.feeInfo.virtualCardRechargeMin *
										1 *
										(state.feeInfo.virtualCardRechargeFeeRate * 0.01))
							}}USDT)
						</div>
					</div>
					<button class="button_box" @click="dialogVisible = true">
						{{ $t('card_shop_v_detail.button') }}
						({{
							value *
							(state.feeInfo.openVirtualCardFee * 1 +
								state.feeInfo.virtualCardRechargeMin * 1 +
								state.feeInfo.virtualCardRechargeMin *
									1 *
									(state.feeInfo.virtualCardRechargeFeeRate * 0.01))
						}}USDT)
					</button>
				</div>
			</div>
			<div class="instructions">
				<div class="title">
					<img src="../../assets/shop/title.png" alt="" class="t_icon" />
					<span>{{ $t('card_shop_v_detail.title') }}</span>
				</div>
				<div class="content">
					<div class="c_title">{{ $t('card_shop_v_detail.c_title1') }}</div>
					<div class="c_body">
						<p>{{ $t('card_shop_v_detail.c_body1_1') }}</p>
						<p>{{ $t('card_shop_v_detail.c_body1_2') }}</p>
					</div>
					<div class="c_title">{{ $t('card_shop_v_detail.c_title2') }}</div>
					<div class="c_body">
						<p>
							{{ $t('card_shop_v_detail.c_body2_1')
							}}{{ state.feeInfo.virtualCardRechargeFeeRate }}%
						</p>
						<p>
							{{ $t('card_shop_v_detail.c_body2_2') }}{{ state.feeInfo.openVirtualCardFee }} USDT
						</p>
					</div>
					<div class="c_title">{{ $t('card_shop_v_detail.c_title3') }}</div>
					<div class="c_body">
						<p>
							1.{{ $t('card_shop_v_detail.c_body3_1') }}{{ state.feeInfo.virtualCardRechargeMin }} USDT
						</p>
						<p>2.{{ $t('card_shop_v_detail.c_body3_2') }}100000 USDT</p>
						<p>3.{{ $t('card_shop_v_detail.c_body3_3') }}50 USDT</p>
						<p>4.{{ $t('card_shop_v_detail.c_body3_4') }}</p>
						<p>
							5.{{ $t('card_shop_v_detail.c_body3_5') }}{{ state.feeInfo.virtualCardRechargeMin }} USDT
						</p>
					</div>
					<div class="c_title">{{ $t('card_shop_v_detail.c_title4') }}</div>
					<div class="c_body">
						<p>1.{{ $t('card_shop_v_detail.c_body4_1') }}</p>
						<p>2.{{ $t('card_shop_v_detail.c_body4_2') }}</p>
						<p>3.{{ $t('card_shop_v_detail.c_body4_3') }}</p>
						<p>4.{{ $t('card_shop_v_detail.c_body4_4') }}</p>
						<p>5.{{ $t('card_shop_v_detail.c_body4_5') }}</p>
					</div>
					<div class="api">
						{{ $t('card_shop_v_detail.api') }}
						<a
							href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCKCHTrMwZvSBflBGMjQBlMmWsSBFSxxnjFWbqjrWcmwVrcFPchwlfrvvXBdMsLFPfLVtFVB"
							target="_blank"
							>bbpayservice@gmail.com</a
						>
					</div>
				</div>
			</div>
		</div>
		<el-dialog v-model="dialogVisible" width="500">
			<span
				>{{ $t('card_shop_v_detail.button') }} ({{
					value *
					(state.feeInfo.openVirtualCardFee * 1 +
						state.feeInfo.virtualCardRechargeMin * 1 +
						state.feeInfo.virtualCardRechargeMin *
							1 *
							(state.feeInfo.virtualCardRechargeFeeRate * 0.01))
				}}USDT)</span
			>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogVisible = false">{{ $t('card_detail.cancel') }}</el-button>
					<el-button type="primary" @click="buy"> {{ $t('card_detail.confirm') }} </el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { getFeeInfoApi, buyVirtualCardApi } from '@/api/index';

const { locale } = useI18n();
const router = useRouter();
const route = useRoute();
let state = reactive({
	feeInfo: {},
	itemInfo: {},
});
const dialogVisible = ref(false);
const value = ref(1);
state.itemInfo = JSON.parse(route.params.itemInfo);
// api 请求
getFeeInfoApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			state.feeInfo = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});

const buy = () => {
	// api 请求
	let params = {
		bin: state.itemInfo.bin,
		batchCount: value.value,
		// cost: value.value * state.feeInfo.openVirtualCardFee,
		cost:
			value.value *
			(state.feeInfo.openVirtualCardFee * 1 +
				state.feeInfo.virtualCardRechargeMin * 1 +
				state.feeInfo.virtualCardRechargeMin *
					1 *
					(state.feeInfo.virtualCardRechargeFeeRate * 0.01)),
	};
	dialogVisible.value = false;
	// 加载ui
	const loadingInstance1 = ElLoading.service({ fullscreen: true });
	// api 请求
	buyVirtualCardApi(params)
		.then((res) => {
			// 关闭加载
			loadingInstance1.close();
			if (res.code == 200) {
				if (locale.value == 'en') {
					ElMessage.success('Successful purchase');
				} else {
					ElMessage.success('购买成功');
				}
				router.push('/admin/cards');
			} else {
				ElMessage.error(res.msg);
			}
		})
		.catch(() => {
			// 关闭加载
			loadingInstance1.close();
		});
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.wallet_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.bread {
			margin: 25px 10px;
			.box {
				display: flex;
				align-items: center;
				.icon_box {
					width: 22px;
					height: 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 2px solid #303133;
					border-radius: 50%;
					box-sizing: border-box;
					margin-right: 7px;
				}
			}
			.box:hover {
				cursor: pointer;

				.icon_box {
					border: 2px solid #409eff;
				}
			}
		}
		.wallet {
			width: 100%;
			background: #ffffff;
			margin-bottom: 15px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			padding: 44px 67px 44px 27px;
			.w_body {
				// height: 202px;
				// padding: 0 52px;
				display: flex;
				align-items: center;
				width: 100%;
				.w_left {
					width: 400px;
					height: 253px;
					margin-right: 70px;
					.card1 {
						width: 400px;
						height: 253px;
						// background: url('../../assets/shop/shop2.png');
						// background-size: 400px 253px;
						// background-repeat: no-repeat;
						margin: 0 30px 30px 0;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						.content_box {
							position: absolute;
							z-index: 2;
							width: 100%;
							height: 100%;
							padding: 27px 33px;
							color: #ffffff;
							.number {
								font-size: 27px;
								margin-bottom: 13px;
								display: none;
							}
							.cardNo {
								font-size: 29px;
								font-weight: 600;
								margin-bottom: 12px;
							}
							.fee {
								margin-bottom: 12px;
								font-size: 17px;
							}
							.cardType {
								font-size: 19px;
							}
						}
						img {
							width: 400px;
							height: 253px;
							border-radius: 20px;
						}
						.button {
							position: absolute;
							z-index: 99;
							width: 133px;
							height: 53px;
							background: #ffffff;
							border-radius: 33px;
							font-weight: 600;
							font-size: 21px;
							color: #333333;
							border: 0;
						}
					}
				}
				.w_right {
					display: flex;
					// align-items: center;
					flex-direction: column;
					.font {
						display: flex;
						align-items: center;
						font-size: 24px;
						color: #333333;
						margin-bottom: 8px;
						font-weight: 600;
						.number {
							width: 80px;
							height: 36px;
						}
						.icon {
							width: 26px;
							margin-left: 26px;
						}
					}
					.content {
						display: flex;
						justify-content: space-between;
						font-weight: 600;
						font-size: 21px;
						color: #333333;
						margin-top: 22px;
						margin-bottom: 20px;
						.item {
							min-width: 250px;
							margin-right: 20px;
						}
					}
					div:last-of-type {
						margin-top: 0;
					}
					.button_box {
						margin-top: 7px;
						width: 627px;
						height: 53px;
						background: #0d0d1f;
						border-radius: 5px;
						border: 1px solid #e4e4e4;
						font-size: 21px;
						color: #ffffff;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.instructions {
				margin-top: 40px;
				width: 1225px;
				min-height: 988px;
				background: #f6f8fc;
				border-radius: 8px;
				padding: 34px 17px 40px 40px;
				.title {
					.t_icon {
						width: 37px;
						margin-right: 17px;
					}
					font-size: 27px;
					color: #333333;
					display: flex;
					font-weight: 600;
					align-items: center;
					margin-bottom: 27px;
				}
				.content {
					display: flex;
					flex-direction: column;
					.c_title {
						font-size: 24px;
						line-height: 33px;
						color: #333333;
						margin-bottom: 6px;
					}
					.c_body {
						font-size: 21px;
						line-height: 33px;
						color: #666666;
						margin-bottom: 36px;
					}
					.api {
						font-size: 24px;
						color: #ff5b00;
						line-height: 24px;
						a {
							color: #ff5b00 !important;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss">
.el-breadcrumb {
	font-size: 22px;
	.el-icon {
		font-size: 14px !important;
	}
}
.el-slider__bar {
	background-color: #1ece47;
	height: 9px !important;
}
.el-slider__button {
	border-radius: 50%;
	background-color: #1ece47;
	border: 2px solid #1ece47;
}
.el-slider__runway {
	background-color: #dddddd;
	height: 9px !important;
}
.el-input-number__decrease,
.el-input-number__increase {
	width: 18px !important;
}
.el-input__wrapper {
	padding-left: 8px !important;
	padding-right: 28px !important;
}
</style>
